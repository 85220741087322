import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const AboutPage = () => (
  <div>
    <SEO 
        title="About Sheep Hosting Bay" 
        description="We contributed considerable time creating the 3 cornerstones of our hosting organization: our website 
        hosting platform, the Web Control Panel and our remarkable 24/7 support."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">About <span className="block text-indigo-600 xl:inline">Sheephostingbay</span></p>
              <p className="section-after-title">
              At Sheephostingbay, we’ve been promoting top web hosting services and also have been keeping viruses far away from you.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="rounded-md shadow">
                      <a href="/domain-search" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                      Get your Domain
                      </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a href="https://sheephostingbay.duoservers.com/domains-brochure/" rel="noreferrer" target="_blank" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      Download Brochure
                      </a>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                We contributed considerable time creating the 3 cornerstones of our hosting organization: our website hosting platform, the Web Control Panel and our remarkable 24/7 support.
                </p>
            </div>

            <div className="mt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                    <div className="relative">
                        <dt>
                            <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                            </svg>
                            </h4>
                            <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Compare Our Prices</h5>
                        </dt>
                        <dd className="mt-1 ml-24 text-base text-gray-500">
                        Uncertain which domain name to select? Go and visit our domain name price comparison table.
                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                            </svg>
                            </h4>
                            <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">TLD Details</h5>
                        </dt>
                        <dd className="mt-1 ml-24 text-base text-gray-500">
                        Read more about the TLDs Sheephostingbay provides. Find just what is the minimum registration 
                        period or if you can get Whois Privacy Protection for the domain name.
                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                            </h4>
                            <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Service Guarantees</h5>
                        </dt>
                        <dd className="mt-1 ml-24 text-base text-gray-500">
                        Sheephostingbay offers a 99.9% network uptime guarantee with every domain name registration.
                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <h4 className="absolute flex items-center justify-center w-20 h-8 rounded-md  tracking-wide font-regular text-sm text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                            </svg>
                            </h4>
                            <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">Contact Us</h5>
                        </dt>
                        <dd className="mt-1 ml-24 text-base text-gray-500">
                        Do you have any questions? Do not delay – contact us at any moment.
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>

    <div className="py-12">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/hosting-platform.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Unique Cloud Hosting Platform</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                            Our cloud hosting platform is completely different from anything else that is found on the web. 
                            It’s created with the vision to exist in the cloud. We have in addition built and launched a 
                            custom internal network within our data centers, which uses only enterprise-level hardware. 
                            Furthermore, we engineered our Web Control Panel and we managed to make it perform 
                            effectively with our cloud hosting system, too.
                            <br/><br/>
                            Our website hosting packages include a 99.9% server uptime guarantee and a 99.9% network uptime 
                            guarantee. And also, if you are not truly satisfied for any reason whatsoever with the service – 
                            we’ve got a 30–day money–back policy.</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/hosting-control-panel-04.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">The Web Control Panel</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Website control is without question something perplexing for completely new customers, because it 
                        requires a huge learning curve. This is the reason we designed an user friendly Web Control 
                        Panel. We developed it as identical as possible to well–known applications customers use 
                        in their day–to–day interactions with their desktop machines and notebooks. This way 
                        there is a minimal learning curve.
                        <br/><br/>
                        What’s more, we have now incorporated various bonus software tools, which can help you protect and 
                        supercharge your current website’s performance or create a brand new site quickly.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/24-7-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Support</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Support is undoubtedly imperative when you manage a web hosting business. Our well trained 
                        specialists are out there 24/7/365. And we all offer you a 1–hour response time warranty, 
                        meaning all tech support queries are addressed within 1 hour.
                        <br/><br/>
                        As a result, it is easy to e–mail us at 12 A.M. on New Year’s Eve and we will be available, 
                        ready to help
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default AboutPage
